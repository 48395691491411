import React, { useState, useEffect } from "react";

const WordGuessingGame = () => {
  const wordList = ["react", "javascript", "frontend", "backend", "coding"]; // Add more words!
  const maxAttempts = 6;

  const [word, setWord] = useState("");
  const [guessedLetters, setGuessedLetters] = useState([]);
  const [wrongGuesses, setWrongGuesses] = useState([]);
  const [attemptsLeft, setAttemptsLeft] = useState(maxAttempts);

  useEffect(() => {
    const randomWord = wordList[Math.floor(Math.random() * wordList.length)];
    setWord(randomWord);
  }, []); // Runs once to set a random word.

  const handleGuess = (letter) => {
    if (guessedLetters.includes(letter) || wrongGuesses.includes(letter)) {
      return; // Ignore already guessed letters.
    }

    if (word.includes(letter)) {
      setGuessedLetters((prev) => [...prev, letter]);
    } else {
      setWrongGuesses((prev) => [...prev, letter]);
      setAttemptsLeft((prev) => prev - 1);
    }
  };

  const renderWord = () => {
    return word
      .split("")
      .map((letter, index) => (guessedLetters.includes(letter) ? letter : "_"))
      .join(" ");
  };

  const handleRestart = () => {
    const randomWord = wordList[Math.floor(Math.random() * wordList.length)];
    setWord(randomWord);
    setGuessedLetters([]);
    setWrongGuesses([]);
    setAttemptsLeft(maxAttempts);
  };

  return (
    <div style={{ textAlign: "center", fontFamily: "Arial, sans-serif" }}>
      <h1>Word Guessing Game</h1>
      <p>Can you guess the word?</p>
      <h2>{renderWord()}</h2>
      <p>Wrong guesses: {wrongGuesses.join(", ")}</p>
      <p>Attempts left: {attemptsLeft}</p>
      <div>
        {"abcdefghijklmnopqrstuvwxyz".split("").map((letter) => (
          <button
            key={letter}
            onClick={() => handleGuess(letter)}
            disabled={
              guessedLetters.includes(letter) || wrongGuesses.includes(letter)
            }
            style={{
              margin: "5px",
              padding: "10px",
              background:
                guessedLetters.includes(letter) || wrongGuesses.includes(letter)
                  ? "#ccc"
                  : "#007BFF",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            {letter}
          </button>
        ))}
      </div>
      {attemptsLeft === 0 && <p>You lost! The word was "{word}".</p>}
      {word.split("").every((letter) => guessedLetters.includes(letter)) && (
        <p>Congratulations! You guessed the word!</p>
      )}
      {(attemptsLeft === 0 ||
        word.split("").every((letter) => guessedLetters.includes(letter))) && (
        <button
          onClick={handleRestart}
          style={{
            marginTop: "20px",
            padding: "10px 20px",
            background: "#28a745",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Restart
        </button>
      )}
    </div>
  );
};

export default WordGuessingGame;
