import React, { useState, useEffect } from "react";
import { fetchData } from "../api/backendAPI";

const Home = () => {
  const [score, setScore] = useState(0);
  const [position, setPosition] = useState({ top: "50%", left: "50%" });

  const moveTarget = () => {
    const randomTop = Math.random() * 80 + "%";
    const randomLeft = Math.random() * 80 + "%";
    setPosition({ top: randomTop, left: randomLeft });
  };

  const handleTargetClick = () => {
    setScore(score + 1);
    moveTarget();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      moveTarget();
    }, 2000); // Move target every 2 seconds
    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  const [data, setData] = useState(null);

  useEffect(() => {
    fetchData("")
      .then((res) => {
        if (res.length > 0) {
          setData(res); // Extract embed_html from the first object
        } else {
          console.warn("No data found in the response");
        }
      })
      .catch((err) => console.error("Error fetching Google Form data:", err));
  }, []); // Runs once on component mount

  if (!data) {
    return <div>Loading the test. Please wait...</div>; // Show loading while data is being fetched
  }

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <h1>Click the Target</h1>
      <h2>Score: {score}</h2>
      <div
        onClick={handleTargetClick}
        style={{
          position: "absolute",
          top: position.top,
          left: position.left,
          width: "50px",
          height: "50px",
          backgroundColor: "red",
          borderRadius: "50%",
          cursor: "pointer",
          transition: "all 0.5s ease",
        }}
      ></div>
    </div>
  );
};

export default Home;
