import { fetchData } from "../api/backendAPI";
import React, { useEffect, useState } from "react";

const GoogleForm = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchData("/api/google-forms/")
      .then((res) => {
        if (res.length > 0) {
          setData(res[0].embed_html); // Extract embed_html from the first object
          console.log("Fetched embed_html:", res[0].embed_html);
        } else {
          console.warn("No data found in the response");
        }
      })
      .catch((err) => console.error("Error fetching Google Form data:", err));
  }, []); // Runs once on component mount

  if (!data) {
    return (
      <center>
        <h1>Loading the test. Please wait...</h1>
      </center>
    ); // Show loading while data is being fetched
  }

  return (
    <center>
      <div
        style={{ width: "100%", height: "100vh", overflow: "hidden" }}
        dangerouslySetInnerHTML={{ __html: data }} // Render the embed HTML directly
      />
    </center>
  );
};

export default GoogleForm;
