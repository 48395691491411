import React, { useEffect, useState } from "react";
import { fetchData } from "../api/backendAPI";

const StudentDashboard = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchData("/api/student/", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => setData(res))
      .catch((err) => console.error(err));
  }, []);

  return (
    <div>
      <h1>Data from Django:</h1>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  );
};

export default StudentDashboard;
