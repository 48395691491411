import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
console.log("API_BASE_URL", API_BASE_URL);

/**
 * Fetch data from an API endpoint.
 * @param {string} endpoint - The endpoint to fetch data from (relative to base URL).
 * @param {object} options - Optional configuration for the request.
 * @param {object} options.params - Query parameters to include in the request.
 * @param {object} options.headers - Headers to include in the request.
 * @param {string} [options.baseURL=API_BASE_URL] - Custom base URL for the request.
 * @returns {Promise<any>} - The response data.
 */
export const fetchData = async (endpoint, options = {}) => {
  const { params = {}, headers = {}, baseURL = API_BASE_URL } = options;

  try {
    const response = await axios.get(`${baseURL}${endpoint}`, {
      params,
      headers,
    });
    console.log("Response Data:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
