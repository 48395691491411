import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./pages/Home";
import GoogleForm from "./components/GoogleForm";
import Login from "./components/Login";
import Signup from "./components/SignUp";
import Navbar from "./components/Navbar";
import StudentDashboard from "./pages/studentdashboard";
import WordGuessingGame from "./components/WordGame";

const App = () => {
  return (
    <Router>
      <div>
        <Navbar />

        <Routes>
          <Route path="/" element={<WordGuessingGame />} />
          <Route path="/googleform" element={<GoogleForm />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/api/student" element={<StudentDashboard />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
