import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Navbar = () => {
  const [showWarning, setShowWarning] = useState(false);
  const [targetPath, setTargetPath] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    if (location.pathname === "/googleform") {
      setTargetPath(path);
      setShowWarning(true);
    } else {
      navigate(path);
    }
  };

  const confirmNavigation = () => {
    setShowWarning(false);
    navigate(targetPath);
  };

  const cancelNavigation = () => {
    setShowWarning(false);
    setTargetPath("");
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <button
                  className="nav-link btn btn-link"
                  onClick={() => handleNavigation("/")}
                >
                  Home
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link btn btn-link"
                  onClick={() => handleNavigation("/googleform")}
                >
                  Test
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link btn btn-link"
                  onClick={() => handleNavigation("/login")}
                >
                  Login
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Warning Popup */}
      {showWarning && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1000,
            background: "white",
            padding: "20px",
            border: "1px solid #ccc",
            borderRadius: "8px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <h4>Testdan rostdan ham chiqmoqchimisan?</h4>
          <p>Test natijasi o'chib ketadi!</p>
          <div style={{ marginTop: "10px" }}>
            <button className="btn btn-danger me-2" onClick={confirmNavigation}>
              Ha, chiqmoqchiman!
            </button>
            <button className="btn btn-secondary" onClick={cancelNavigation}>
              Yo'q, testni davom ettiraman
            </button>
          </div>
        </div>
      )}
      {/* Overlay */}
      {showWarning && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)",
            zIndex: 999,
          }}
        ></div>
      )}
    </>
  );
};

export default Navbar;
